import * as React from "react"
import styled from "styled-components"

import TagList from "../components/TagList"
import Header from "../components/Header"
import Footer from "../components/Footer"

import { Helmet } from 'react-helmet'
import { Link } from "gatsby"

const Container = styled.div`
  font-family: monospace;
`

const Main = styled.div`
  margin: 3rem auto;
  max-width: 640px;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tag-list {
    display: inline-block;
    width: 120px;
    vertical-align: top;
    margin: 1em 1em 1em 0;
  }
`

const TagListContainer = styled.div`
  max-width: 640px;
`

const Img = styled.img`
  width: 100%;
  max-width: 640px;
  margin-bottom: 1em;
`

const ParkName = styled.h3`
  margin: 2em 0;
`

const Links = styled.div`
  a {
    display: inline-block;
    margin-right: 1em;
  }
`

const DogParkPage = ({ pageContext }) => {
  const { park } = pageContext;
  const imgPath = "../../" + park.img;
  const title = "dogpark.fyi - " + park.name;
  console.log(park);
  return (
    <Container>
      <Helmet>
        <html lang='en' />
        <title>{title}</title>
        <meta name='description' content={park.description} />
        {park.img &&
          <meta property='og:image' content={imgPath} />
        }
      </Helmet>
      <Main>
        <Header />
        <ParkName>{park.name}</ParkName>
        <p>
            {park.descriptionExtended &&
                park.descriptionExtended
            }
            {!park.descriptionExtended &&
                park.description
            }
        </p>
        <TagListContainer>
          {park.seating && 
            <TagList title="Seating" tags={park.seating}/>
          }
          {park.enclosure && 
            <TagList title="Enclosure" tags={park.enclosure}/>
          }
          {park.water && 
            <TagList title="Water" tags={park.water}/>
          }
          {park.surfaces && 
            <TagList title="Surfaces" tags={park.surfaces}/>
          }
        </TagListContainer>
        {park.img &&
          <Img src={imgPath} />
        }
        <h4>Links</h4>
        <Links>
          {park.googleMapsShortLink &&
            <Link to={park.googleMapsShortLink}>Google Maps</Link>
          }
          {park.websiteLink &&
            <Link to={park.websiteLink}>Website</Link>
          }
        </Links>
      </Main>
      <Footer />
    </Container>
  )
}

export default DogParkPage
